import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import { ITransactionData } from "@services/providers/types";
import { isPlatformServer } from '@angular/common';

interface Referral {
  id: number,
  code: string,
  parent_code: string,
  createdAt: string,
  updatedAt: string,
  publishedAt: string,
  parent_user: 1
}

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  private data: Referral | null = null;
  private storageKey = 'referral-code'

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {}

  public async fetch(): Promise<Referral> {
    const url = '/api/user-referral'
    const request = this.http.get<Referral>(url);
    const response = await firstValueFrom<Referral>(request)
    this.data = response
    return response
  }

  public get(): Referral | null {
    return this.data
  }

  public async withdraw(wallet: string): Promise<ITransactionData> {
    const url = '/api/referral-reward/withdraw';
    const request = this.http.post<ITransactionData>(url, { wallet })
    const response = await firstValueFrom<ITransactionData>(request)
    return response
  }

  public saveCode(code: string): void {
    if (isPlatformServer(this.platformId))
    {
      return;
    }
    
    window.localStorage.setItem(this.storageKey, code)
  }

  public getCode(): string {
    try {
      const code = window.localStorage.getItem(this.storageKey)
      if (code) {
        return code
      }

      return ''
    } catch (e) {
      console.error(e)
      return ''
    }
  }

  public removeCode(): void {
    window.localStorage.removeItem(this.storageKey)
  }
}
