import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStrapiMultiResponse } from "@common/api/response";
import { IWallet, IWalletRes } from '@common/api/wallets';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

export interface WalletChains
{
    sale: number[];
    vesting: number[];
}

@Injectable({
    providedIn: 'root'
})
export class WalletService
{
    private currentWallet$: BehaviorSubject<IWalletRes | undefined> = new BehaviorSubject<IWalletRes | undefined>(undefined);

    constructor(
        private http: HttpClient,
    )
    {
    }

    get CurrentWallet$(): Observable<IWalletRes | undefined>
    {
        return this.currentWallet$.asObservable();
    }

    public async getWallet(): Promise<IWalletRes>
    {
        let endpoint = `/api/wallet-get-update`;

        const walletInfo = await firstValueFrom(this.http.get<IWalletRes>(endpoint));

        this.currentWallet$.next(walletInfo);

        return walletInfo;
    }

    public async getByAddress(wallet: string): Promise<IWallet | null>
    {
        const url = `/api/wallets?filters[address][$eq]=${ wallet.toLowerCase() }`;
        const request = this.http.get<IStrapiMultiResponse<IWallet>>(url);
        const response = await firstValueFrom(request);

        const walletData = response.data[ 0 ];
        if (walletData)
        {
            return walletData;
        }

        return null;
    }

    public async update(wallet: Partial<IWallet>): Promise<IWallet>
    {
        const url = `/api/wallets/${ wallet.id }`;
        const body = {
            data: wallet
        };
        const request = this.http.put<IWallet>(url, body);
        const response = await firstValueFrom<IWallet>(request);

        return response;
    }

    public async getChains(wallet: string): Promise<WalletChains>
    {
        const url = `/api/wallet/all-chains?wallet=${ wallet }`;
        const request = this.http.get<WalletChains>(url);
        const response = await firstValueFrom<WalletChains>(request);

        return response;
    }
}
